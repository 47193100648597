import React, { useState, useContext } from 'react';
import { string, func } from 'prop-types';
import asyncStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';

import { Request } from '@opusonesolutions/gridos-app-framework';
import { AssetViolationsContext } from 'contexts/AssetViolationsContext';
import { useAsset } from 'contexts/AssetContext';
import UploadButton from 'components/UploadButton';
import { TYPE_MAP } from '../../../../helpers/NetworkHelpers';

import './BulkLifecycleUpload.scss';

const uploadAssetLifecycles = async (workspace, branch, feeder, file, setLoadingState) => {
  const fileData = new FormData();
  fileData.append('asset_lifecycles', file);
  setLoadingState({ status: asyncStates.LOADING });
  const req = new Request(
    `/api/workspace/${workspace}/branch/${branch}/feeder/${feeder}/lifecycles`,
  );
  try {
    await req.post(fileData);
    setLoadingState({ status: asyncStates.SUCCESS });
  } catch (error) {
    setLoadingState({
      message:
        error.response.data.message ||
        'There was an unexpected issue uploading your lifecycle data. Please try again later.',
      status: error.response.status,
    });
  }
};

function BulkLifecycleUpload({
  workspace,
  branch,
  feeder,
  afterUpload,
  selectedAssetID,
  selectedAssetViewModelClass,
}) {
  const [uploadState, setLoadingState] = useState({
    status: asyncStates.INITIAL,
  });
  const { refetchOldAssets } = useContext(AssetViolationsContext);
  const { refetch } = useAsset(selectedAssetID, TYPE_MAP[selectedAssetViewModelClass]?.assetType);
  const isUploading = uploadState.status === asyncStates.LOADING;
  const uploadFailed = uploadState.status >= 400;

  const handleFileUpload = async e => {
    Analytics.logEvent('Asset Lifecycle File Upload', 'Asset Lifecyle');
    await uploadAssetLifecycles(workspace, branch, feeder, e.target.files[0], setLoadingState);
    refetch();
    refetchOldAssets();
    if (afterUpload) afterUpload();
  };

  return (
    <div className="bulk-lifecycle-header" style={{ backgroundColor: '#ffffff' }}>
      <h3 className="bulk-lifecycle-header">Asset Lifecycles</h3>
      <div className="bulk-lifecycle-section">
        {uploadFailed ? (
          <p className="warning">
            {uploadState.status === 400
              ? uploadState.message
              : 'There was an unexpected issue uploading your lifecycle data. Please try again later.'}
          </p>
        ) : null}
        Upload a comma separated file (.csv) to bulk update lifecycle information for assets. This
        file should have headers for:
        <ul>
          <li>Asset Name (asset_name)</li>
          <li>In Service Date (in_service_date YYYY-MM-DD)</li>
          <li>Retired Date (retired_date YYYY-MM-DD)</li>
        </ul>
        Click{' '}
        <a
          href="/samples/sample_asset_lifecycles.csv"
          download="sample_asset_lifecycles.csv"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>{' '}
        to download a sample file.
        <UploadButton onChange={handleFileUpload} loading={isUploading} accept=".csv" />
      </div>
    </div>
  );
}

BulkLifecycleUpload.defaultProps = {
  afterUpload: undefined,
  selectedAssetID: null,
  selectedAssetViewModelClass: null,
};

BulkLifecycleUpload.propTypes = {
  workspace: string.isRequired,
  branch: string.isRequired,
  feeder: string.isRequired,
  afterUpload: func,
  selectedAssetID: string,
  selectedAssetViewModelClass: string,
};

export default BulkLifecycleUpload;
