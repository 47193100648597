import React, { FunctionComponent, useState, useEffect, ReactChild } from 'react';
import { ThemeProp } from 'types/index';
import ReactSelect, { components, createFilter } from 'react-select';
import ArrowDown from 'components/Icons/ArrowDown';
import ArrowUp from 'components/Icons/ArrowUp';

import Tooltip from 'components/Tooltip';
import './Select.scss';
import CustomCheckbox from 'components/CustomCheckbox';

type SelectOption = {
  value: any;
  label: any;
  disabled?: boolean;
};

type SelectProps = {
  value?: any;
  options: SelectOption[];
  defaultOptions?: SelectOption[];
  disabled?: boolean;
  searchable?: boolean;
  clearable?: boolean;
  isMulti?: boolean;
  onChange: (option: any) => void;
  height?: number;
  width?: number;
  onMenuOpen?: (event?: React.FormEvent<HTMLElement>) => void;
  onMenuClose?: (event?: React.FormEvent<HTMLElement>) => void;
  loadOptions?: (event?: React.FormEvent<HTMLElement>) => [];
  async?: boolean;
  tooltip?: string;
  placeholder?: string;
  theme?: ThemeProp;
  className?: string;
  id?: string;
  htmlFor?: string;
  type?: 'default' | 'primary' | 'secondary' | 'text';
  label?: ReactChild;
  labelPosition?: 'top' | 'left';
  invalid?: boolean;
  noOptionsMessage?: string;
  multiSelectType?: 'default' | 'checkbox';
};

const Select: FunctionComponent<SelectProps> = ({
  theme = 'light',
  value = '',
  options = [],
  disabled = false,
  searchable = true,
  clearable = true,
  isMulti = false,
  onMenuOpen = null,
  onMenuClose = null,
  loadOptions = () => [],
  defaultOptions = null,
  async = false,
  tooltip = '',
  placeholder = 'Select...',
  onChange,
  className = '',
  type = 'default',
  id = '',
  htmlFor = '',
  label,
  labelPosition = 'top',
  width = null,
  invalid = false,
  noOptionsMessage = 'No options',
  multiSelectType = 'default',
}) => {
  const themes: any = {
    light: {
      backgroundColor: '#FFFFFF',
      hoverColor: { default: '#ECF3FA', primary: '#ECF3FA', secondary: '#ECF3FA' },
      selectedColor: '#0337A4',
      borderColor: '#ECF3FA',
      disabledColor: '#cccccc',
      highlightColor: '#ECF3FA',
      menuColor: '#FFFFFF',
      borderRadius: '0',
    },
  };

  const [selectOptions, setSelectOptions] = useState(defaultOptions || options);
  const [menuOpen, setMenuOpen] = useState(false);
  let actualValue = null;
  let multiSelectValue = null;
  if (value instanceof Array) {
    const selectOptionValues = selectOptions.map(({ value: val }) => val);
    multiSelectValue = value.filter(({ value: val }) => selectOptionValues.includes(val));
  } else {
    actualValue = selectOptions.find(({ value: val }) => val === value) ?? null;
  }
  const onSelectMenuOpen = () => {
    if (async) {
      setSelectOptions(loadOptions());
    }
    setMenuOpen(true);
    return onMenuOpen && onMenuOpen();
  };

  const onSelectMenuClose = () => {
    setMenuOpen(false);
    return onMenuClose && onMenuClose();
  };
  const DropdownIndicator = (props: { isDisabled: boolean }) =>
    menuOpen ? (
      <ArrowUp color={themes[theme].textColor} />
    ) : (
      <ArrowDown color={props.isDisabled ? themes[theme].disabledColor : themes[theme].textColor} />
    );
  const CustomOption = (props: any) => (
    <components.Option {...props}>
      <div className="option-row">
        <div title={props.data?.label} className="label-row">
          <CustomCheckbox
            id="empty-workspace"
            checked={
              props &&
              props.selectProps?.value &&
              props?.selectProps?.value?.filter(
                (obj: SelectOption) => obj.value === props.data?.value,
              )?.length !== 0
            }
            type="primary"
          />
          <div className="label-text">{props.data?.label}</div>
        </div>
      </div>
    </components.Option>
  );

  const CustomSingleOption = (props: any) => (
    <components.Option {...props}>
      <div className="option-row">
        <div title={props.data?.label} className="label-row">
          <div className="label-text">{props.data?.label}</div>
        </div>
      </div>
    </components.Option>
  );

  const MultiValueContainer = (props: any) => {
    const selectLabel = props.data?.label;
    const allSelected = props.selectProps?.value;
    const index = allSelected?.findIndex(
      (selected: SelectOption) => selected.label === selectLabel,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const selectedMsg = isLastSelected
      ? `${allSelected?.length}/${selectOptions?.length} selected`
      : '';
    return <div className="custom-select-multi-value">{selectedMsg}</div>;
  };
  useEffect(() => {
    if (async && defaultOptions) {
      setSelectOptions(defaultOptions);
    }
  }, [defaultOptions, async]);
  useEffect(() => {
    if (options && !async) {
      setSelectOptions(options);
    }
  }, [options, async]);

  return (
    <div className={`select-dropdown-section label-${labelPosition}`}>
      {label && (
        <label htmlFor={htmlFor || id} className={`select__label ${type}`}>
          {label}
        </label>
      )}
      <Tooltip content={tooltip}>
        <ReactSelect
          id={id}
          className={`select-dropdown ${className} ${type} ${theme} ${menuOpen ? 'menu-open' : ''}`}
          menuIsOpen={menuOpen}
          value={actualValue || multiSelectValue}
          isDisabled={disabled}
          onChange={onChange}
          isSearchable={searchable}
          isClearable={clearable}
          isMulti={isMulti}
          onMenuOpen={onSelectMenuOpen}
          onMenuClose={onSelectMenuClose}
          classNamePrefix={actualValue ? 'custom-select' : 'custom-multi-select'}
          isOptionDisabled={option => option.disabled}
          options={selectOptions}
          filterOption={createFilter({
            matchFrom: 'any',
            stringify: (option: SelectOption) => `${option.label}`,
          })}
          closeMenuOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          blurInputOnSelect={!isMulti || (isMulti && multiSelectType !== 'checkbox')}
          hideSelectedOptions={isMulti && multiSelectType !== 'checkbox'}
          components={{
            Option: isMulti && multiSelectType === 'checkbox' ? CustomOption : CustomSingleOption,
            DropdownIndicator,
            MultiValueContainer:
              isMulti && multiSelectType === 'checkbox'
                ? MultiValueContainer
                : components.MultiValueContainer,
          }}
          placeholder={placeholder}
          noOptionsMessage={() => noOptionsMessage}
          styles={{
            indicatorSeparator: defaults => ({
              ...defaults,
              width: 0,
            }),
            option: (defaults, state) => {
              let color = themes[theme].textColor;
              if (state.isDisabled) {
                color = themes[theme].disabledColor;
              }
              return {
                ...defaults,
                color,
                ':hover': {
                  backgroundColor:
                    (type === 'default' || type === 'primary' || type === 'secondary') &&
                    state.isSelected
                      ? themes[theme].selectedColor
                      : '#ECF3FA',
                },
              };
            },
            menu: defaults => ({
              ...defaults,
              marginTop: '4px',
              padding: '3px 0',
              zIndex: 1000,
              borderColor: '#ECF3FA',
              boxShadow: '6px 5px 5px rgb(160 160 160 / 50%)',
              backgroundColor: themes[theme].menuColor,
              borderWidth: '0.5px',
              borderStyle: 'solid',
            }),
            control: defaults => ({
              ...defaults,
              backgroundColor:
                theme === 'light' && type === 'primary' ? '#ECF3FA' : themes[theme].backgroundColor,
              borderColor: invalid ? '#FC7169 !important' : themes[theme].borderColor,
              width: width || 'auto',
              boxShadow: 'none',
            }),
            container: defaults => ({
              ...defaults,
              backgroundColor: 'transparent',
              borderColor: themes[theme].borderColor,
              maxWidth: width || 'auto',
            }),
            valueContainer: defaults => ({
              ...defaults,
              padding: '0 8px',
              color: theme === 'dark' ? '#fff' : '#000',
            }),
            clearIndicator: defaults => ({
              ...defaults,
              padding: 0,
            }),
            dropdownIndicator: (defaults, state) => {
              let color = '#0337A4';
              if (state.isDisabled) color = themes[theme].disabledColor;
              return {
                ...defaults,
                paddingLeft: 0,
                color,
              };
            },
            singleValue: (defaults, state) => ({
              ...defaults,
              width: 'calc(100% - 15px)',
              color:
                state.data.disabled && ['primary', 'secondary', 'default'].includes(type)
                  ? '#95a6b8'
                  : '#000',
            }),
            multiValue: styles => ({
              ...styles,
              backgroundColor: theme === 'dark' ? '#606060' : '#fff',
              border: '1px solid #606060',
              borderRadius: '0px',
            }),
            multiValueLabel: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
            }),
            multiValueRemove: styles => ({
              ...styles,
              color: theme === 'dark' ? 'white' : '#606060',
              ':hover': {
                color: '#0337A4',
              },
            }),
          }}
          theme={(currentTheme: any) => ({
            ...currentTheme,
            colors: {
              ...currentTheme.colors,
              primary25: themes[theme].hoverColor,
              primary: themes[theme].selectedColor,
              primary50: themes[theme].hoverColor,
              neutral0: themes[theme].backgroundColor.default,
              neutral80: themes[theme].textColor,
            },
          })}
        />
      </Tooltip>
    </div>
  );
};
export default Select;
