import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import ArrowDown from 'components/Icons/ArrowDown';
import nullable from 'helpers/nullablePropType';
import { isDate } from 'helpers/calendarHelper';
import Card from '../Card';
import Button from '../Button';
import IconButton from '../IconButton';
import Calendar from '../Calendar/Calendar';
import PermissionDisabledTip from '../PermissionDisabledTip';
import './CalendarPicker.scss';

class CalendarPicker extends Component {
  state = {
    calendarOpen: false,
    selectStart: true,
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    const newStartDate = startDate ? moment.utc(startDate) : moment.utc().startOf('hour');

    const newEndDate = endDate
      ? moment.utc(endDate)
      : moment.utc(newStartDate).add(7, 'day').endOf('hour');

    if (isDate(newStartDate)) this.setState({ tempStartDate: newStartDate.toISOString() });

    if (isDate(newEndDate)) this.setState({ tempEndDate: newEndDate.toISOString() });

    this.setCalendarType(newStartDate, newEndDate);
  }

  componentDidUpdate(prevProps) {
    const { startDate, endDate } = this.props;
    const { startDate: prevStartDate, endDate: prevEndDate } = prevProps;

    const isSameStart = moment.utc(startDate).isSame(moment.utc(prevStartDate), 'minute');
    const isSameEnd = moment.utc(endDate).isSame(moment.utc(prevEndDate), 'minute');

    if (!isSameStart) {
      this.setState({ tempStartDate: moment.utc(startDate).toISOString() });
    }
    if (endDate && prevEndDate && !isSameEnd) {
      this.setState({ tempEndDate: moment.utc(endDate).toISOString() });
    }

    if (!isSameStart || (endDate && prevEndDate && !isSameEnd)) {
      this.setCalendarType(startDate, endDate);
    }
  }

  getMaxHourLimit = () => {
    let type = 1;
    switch (this.props.subHourInterval) {
      case 5:
        type = 1;
        break;
      case 15:
        type = 6;
        break;
      case 30:
        type = 12;
        break;
      default:
        type = 1;
    }
    return type;
  };

  // range breaks for each calendar type, in minutes
  CALENDAR_TYPES = () => ({
    years: { displayRange: moment.duration(10, 'y') },
    months: { displayRange: moment.duration(5, 'y') },
    days: { displayRange: moment.duration(2, 'M') },
    hours: { displayRange: moment.duration(48, 'h') },
    minutes: { displayRange: moment.duration(this.getMaxHourLimit(), 'h') },
  });

  setCalendarType = (start, end) => {
    if (this.props.currentView) {
      this.setState({ calendarType: this.props.currentView });
    } else {
      let calendarType = 'years';
      const tempEnd = end ? moment.utc(end) : moment.utc(start).add(7, 'day').endOf('hour');
      Object.values(this.CALENDAR_TYPES()).forEach((timespan, index) => {
        if (tempEnd.isBefore(moment.utc(start).add(timespan.displayRange))) {
          calendarType = Object.keys(this.CALENDAR_TYPES())[index];
        }
      });
      if (this.props.minutesDisabled && calendarType === 'minutes') calendarType = 'hours';
      this.setState({ calendarType });
    }
  };

  toggleCalendar = () => {
    if (this.state.calendarOpen) {
      this.setState({ tempStartDate: this.props.startDate, tempEndDate: this.props.endDate });
      this.setCalendarType(this.props.startDate, this.props.endDate);
    }
    const { calendarOpen } = this.state;
    const toggleOpen = !calendarOpen;
    this.setState({ calendarOpen: toggleOpen });
  };

  handleChange = evt => evt.preventDefault();

  handleDateChange = date => {
    let current;
    let newDate;
    let newEndDate;
    let newStartDate;
    const calendarType =
      this.state.calendarType === 'days' ? 'date' : this.state.calendarType.slice(0, -1);
    if (!this.state.selectStart) {
      current = 'tempEndDate';
      newDate = moment.utc(date).endOf(calendarType).toISOString();
      newStartDate = moment.utc(date).startOf(calendarType).toISOString();
    } else {
      current = 'tempStartDate';
      newDate = moment.utc(date).startOf(calendarType).toISOString();
      newEndDate = moment.utc(date).endOf(calendarType).toISOString();
    }

    let selectStart = current === 'tempEndDate';

    if (
      current === 'tempStartDate' &&
      moment.utc(newDate).isAfter(moment.utc(this.state.tempEndDate))
    ) {
      this.setState({ tempEndDate: moment.utc(newDate).endOf(calendarType) });
    }
    if (
      current === 'tempEndDate' &&
      moment.utc(newDate).isBefore(moment.utc(this.state.tempStartDate))
    ) {
      newEndDate = moment.utc(newDate).endOf(calendarType);
      newDate = newStartDate;
      this.setState({ tempEndDate: newEndDate });
      current = 'tempStartDate';
      selectStart = false;
    }
    this.setState({
      [current]: newDate,
      calendarOpen: true,
      selectStart,
    });
  };

  applyDateRange = () => {
    const { onApply } = this.props;
    if (typeof onApply === 'function') {
      onApply(this.state.tempStartDate, this.state.tempEndDate, this.state.calendarType);
    }
    this.setState({ calendarOpen: false });
  };

  changeTimeFrame = calendarType => this.setState({ calendarType });

  render() {
    const {
      theme,
      startDate,
      endDate,
      dots,
      styleFor,
      disabled,
      minutesDisabled,
      selectedAnalysis,
      onViewChanged,
      subHourInterval,
      inputStyle,
      currentView,
      showHeader,
      disabledMonths,
      validStartMonth,
    } = this.props;
    const displayEndDate = endDate
      ? moment.utc(moment(endDate))
      : moment.utc(moment(startDate)).add(7, 'day').endOf('day');
    const { tempStartDate, tempEndDate, selectStart, calendarOpen, calendarType } = this.state;
    const inValidMonth =
      validStartMonth && moment.utc(validStartMonth, 'MMMM').isBefore(moment.utc(tempStartDate));
    const invalidStartEndDate =
      !validStartMonth &&
      tempStartDate &&
      tempEndDate &&
      moment.utc(tempEndDate).diff(moment.utc(tempStartDate), 'year') >= 10;
    const invalidMinuteSelected =
      !validStartMonth &&
      tempStartDate &&
      tempEndDate &&
      moment.utc(tempStartDate).isSame(moment.utc(tempEndDate), 'minute');
    const isDisabled = inValidMonth || invalidStartEndDate || invalidMinuteSelected;
    let permissionDisabledMSg = '';
    if (inValidMonth) {
      permissionDisabledMSg = 'Invalid selection of starting month';
    } else if (invalidMinuteSelected) {
      permissionDisabledMSg = 'Please select end minute';
    } else if (invalidStartEndDate) {
      permissionDisabledMSg = 'Please select no more than 10 years';
    }
    const calendarText = () => {
      if (currentView && currentView === 'months') {
        const displayMonthStart = moment.utc(startDate).format('MMMM');
        const displayMonthEnd = displayEndDate.format('MMMM');
        if (displayMonthStart === displayMonthEnd) {
          return displayMonthStart;
        }
        return `${displayMonthStart} - ${displayMonthEnd}`;
      }
      return `${moment.utc(startDate).format('DD MMMM YYYY HH:mm')} - ${displayEndDate.format(
        'DD MMMM YYYY HH:mm',
      )}`;
    };
    return (
      <div
        className={`${theme} calendar-picker-container style-for-${styleFor} ${inputStyle} ${
          currentView ? 'single-view' : ''
        }`}
      >
        <Button
          onClick={this.toggleCalendar}
          type="text"
          style={{ marginBottom: '1rem' }}
          className="calendar-button"
          theme={theme}
          square
          calendarOpen={calendarOpen}
          id="toggle"
          disabled={disabled}
          title={`${moment.utc(startDate).format('DD MMMM YYYY HH:mm')} - ${displayEndDate.format(
            'DD MMMM YYYY HH:mm',
          )}`}
        >
          <span className="calendar-text">{calendarText()}</span>
          <span className="icon-indicator">
            <ArrowDown color={theme === 'dark' ? '#fff' : '#000'} />
          </span>
        </Button>
        {calendarOpen && (
          <div className="calendar-container">
            <Card hideTitle theme={theme}>
              <div className="type-calendar">
                <div className="close-button-row">
                  <IconButton
                    onClick={this.toggleCalendar}
                    icon="close"
                    theme={this.props.theme}
                    id="close"
                  />
                </div>
                <div className="menu-calendar">
                  {!currentView && (
                    <div className="menu-column">
                      {Object.keys(this.CALENDAR_TYPES())
                        .reverse()
                        .map(time => (
                          <Button
                            key={time}
                            id={time}
                            type="text"
                            onClick={() => this.setState({ calendarType: time })}
                            className={classnames({
                              selected: this.state.calendarType === time,
                              'not-selected': this.state.calendarType !== time,
                            })}
                            theme={theme}
                            disabled={time === 'minutes' ? minutesDisabled : false}
                          >
                            {time.charAt(0).toUpperCase() + time.slice(1)}
                          </Button>
                        ))}
                    </div>
                  )}
                  <div>
                    <div className="calendar-apply">
                      <Calendar
                        dots={dots}
                        startDate={tempStartDate}
                        endDate={tempEndDate}
                        selectStart={selectStart}
                        onDateChanged={this.handleDateChange}
                        onViewChanged={onViewChanged}
                        calendarType={calendarType}
                        styleFor={this.props.styleFor}
                        theme={this.props.theme}
                        selectedAnalysis={selectedAnalysis}
                        subHourInterval={subHourInterval}
                        showHeader={showHeader}
                        disabledMonths={disabledMonths}
                      />
                    </div>
                  </div>
                </div>
                <div className="calendar-footer">
                  {!currentView && (
                    <div className="current-selection-container">
                      <div className="current-selection">Current Selection:</div>
                      <div className="current-selection">
                        {`${moment
                          .utc(tempStartDate)
                          .format('DD MMMM YYYY HH:mm:ss')} - ${moment
                          .utc(tempEndDate)
                          .format('DD MMMM YYYY HH:mm:ss')}`}
                      </div>
                    </div>
                  )}
                  <PermissionDisabledTip
                    hide={!isDisabled}
                    placement="top"
                    theme={theme}
                    message={permissionDisabledMSg}
                    title="Time Range Limit"
                  >
                    <Button
                      id="apply"
                      disabled={isDisabled}
                      theme="light"
                      onClick={this.applyDateRange}
                    >
                      Apply
                    </Button>
                  </PermissionDisabledTip>
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

CalendarPicker.defaultProps = {
  endDate: null,
  onApply: null,
  theme: 'light',
  disabled: false,
  dots: ['scenario'],
  styleFor: 'timebar',
  selectedAnalysis: null,
  minutesDisabled: false,
  subHourInterval: 60,
  inputStyle: 'default',
  currentView: null,
  showHeader: true,
  disabledMonths: [],
  validStartMonth: null,
};

CalendarPicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
  onApply: PropTypes.func,
  onViewChanged: nullable(PropTypes.func).isRequired,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  styleFor: PropTypes.oneOf(['timebar', 'topnav']),
  inputStyle: PropTypes.oneOf(['default', 'primary']),
  dots: PropTypes.arrayOf(PropTypes.string),
  selectedAnalysis: PropTypes.string,
  minutesDisabled: PropTypes.bool,
  subHourInterval: PropTypes.number,
  currentView: PropTypes.string,
  showHeader: PropTypes.bool,
  disabledMonths: PropTypes.array,
  validStartMonth: PropTypes.string,
};

export default CalendarPicker;
