/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import IconButton from 'components/IconButton';
import ThemedTabs from 'components/ThemedTabs';
import PermissionDisabledTip from 'components/PermissionDisabledTip';
import asyncActionStates from 'helpers/asyncActionStates';

import WorkspaceSettingsContextProvider from 'contexts/WorkspaceSettingsContext';
import { Request, useAuthContext } from '@opusonesolutions/gridos-app-framework';

import AnalyticsConfig from './AnalyticsConfig';
import NetworkImportsPanel from './NetworkImportsPanel';
import GisSettingsPanel from './GisSettingsPanel';
import ScheduleSourcePanel from './ScheduleSourcePanel';

const WorkspaceCard = ({
  canEditAnalytics,
  canEditCimDatastore,
  canViewUsers,
  canDelete,
  canDownload,
  permissions,
  displayAlertMessage,
  onDeleteWorkspace,
  onToggle,
  workspaceCardExpanded,
  theme,
  workspace,
  setConfirmModalConfig,
  onTabClick,
  workspaceCardStartExpanded,
}) => {
  // Download workspace state
  const [downloadState, setDownloadState] = useState(asyncActionStates.INITIAL);
  const { isAuthEnabled } = useAuthContext();
  const downloadWorkspace = async () => {
    setDownloadState(asyncActionStates.LOADING);
    const request = new Request(`/api/workspace/${workspace}/branch/master/xml`);

    try {
      const { data } = await request.get();
      const blob = new Blob([data], { type: 'application/xml' });
      FileSaver.saveAs(blob, `${workspace}.xml`);
      setDownloadState(asyncActionStates.SUCCESS);
    } catch (error) {
      setDownloadState(asyncActionStates.ERROR);
      // TODO: figure out where to get this from
      displayAlertMessage(
        'Download Failed',
        'There was a problem downloading your selected file. Please try again.',
        null,
        () => downloadWorkspace(workspace),
      );
    }
  };

  useEffect(() => {
    if (workspaceCardStartExpanded) {
      onToggle(!workspaceCardExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`workspace-card ${workspaceCardExpanded ? 'workspace-card-open' : ''}`}>
      <div className="workspace-card-header">
        <div className="link" onClick={() => onToggle(!workspaceCardExpanded)}>
          <Link to={`${workspace}/master/gis`} onClick={e => e.stopPropagation()}>
            <h2 className="workspace-card-title">{workspace}</h2>
          </Link>
        </div>
        <PermissionDisabledTip
          title="Download Network"
          placement="bottom"
          hide={canDownload}
          theme={theme}
        >
          {downloadState === asyncActionStates.LOADING ? (
            <div className="loading-spinner">
              <i className="material-icons rotate">autorenew</i>
            </div>
          ) : (
            <IconButton
              icon="get_app"
              className="download"
              disabled={!canDownload}
              onClick={() => downloadWorkspace()}
              theme={theme}
              tooltip="Download Workspace"
            />
          )}
        </PermissionDisabledTip>
        <PermissionDisabledTip
          title="Delete Workspace"
          placement="bottom"
          hide={canDelete}
          theme={theme}
        >
          <IconButton
            icon="delete"
            theme={theme}
            disabled={!canDelete}
            className="delete"
            onClick={() => onDeleteWorkspace(workspace)}
            tooltip="Delete Workspace"
          />
        </PermissionDisabledTip>
        <IconButton
          className="open-button"
          icon={workspaceCardExpanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          onClick={() => onToggle(!workspaceCardExpanded)}
          theme={theme}
        />
      </div>
      {workspaceCardExpanded && (
        <div className="workspace-card-body">
          <WorkspaceSettingsContextProvider
            workspace={workspace}
            permissions={permissions}
            isAuthEnabled={isAuthEnabled}
          >
            <ThemedTabs
              theme={theme}
              underline="line"
              tabs={[
                { name: 'Analytics' },
                { name: 'Network Imports' },
                { name: 'GIS Settings' },
                { name: 'Schedule Source' },
              ]}
              onSelect={() => onTabClick()}
            >
              {TabPanel => (
                <>
                  <TabPanel>
                    <AnalyticsConfig
                      disabled={!canEditAnalytics}
                      displayAlertMessage={displayAlertMessage}
                      open={workspaceCardExpanded}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <NetworkImportsPanel
                      disabled={!canViewUsers}
                      displayAlertMessage={displayAlertMessage}
                      open={workspaceCardExpanded}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <GisSettingsPanel
                      disabled={!canEditCimDatastore}
                      displayAlertMessage={displayAlertMessage}
                      open={workspaceCardExpanded}
                      theme={theme}
                      workspace={workspace}
                    />
                  </TabPanel>
                  <TabPanel>
                    <ScheduleSourcePanel
                      theme={theme}
                      workspace={workspace}
                      disabled={!canEditAnalytics}
                      setConfirmModalConfig={setConfirmModalConfig}
                      onTabClick={() => onTabClick()}
                    />
                  </TabPanel>
                </>
              )}
            </ThemedTabs>
          </WorkspaceSettingsContextProvider>
        </div>
      )}
    </div>
  );
};

WorkspaceCard.defaultProps = {
  canEditAnalytics: false,
  canEditCimDatastore: false,
  canDelete: false,
  canDownload: false,
  canViewUsers: false,
  workspaceCardExpanded: false,
  workspaceCardStartExpanded: false,
};

WorkspaceCard.propTypes = {
  canEditAnalytics: PropTypes.bool,
  canEditCimDatastore: PropTypes.bool,
  canDelete: PropTypes.bool,
  canDownload: PropTypes.bool,
  canViewUsers: PropTypes.bool,
  permissions: PropTypes.object.isRequired,
  displayAlertMessage: PropTypes.func.isRequired,
  onDeleteWorkspace: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  workspaceCardExpanded: PropTypes.bool,
  theme: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  setConfirmModalConfig: PropTypes.func.isRequired,
  onTabClick: PropTypes.func.isRequired,
  workspaceCardStartExpanded: PropTypes.bool,
};

export default WorkspaceCard;
