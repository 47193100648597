import React, { FunctionComponent } from 'react';
import { IconPropsSchema } from '../../types/index';

const ArrowDown: FunctionComponent<IconPropsSchema> = ({
  height = '20px',
  width = '30px',
  color = '#000000',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>FBE00E5E-DA03-4D02-93A7-2F713A7AEACD</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icon-Library-Copy"
        transform="translate(-868.000000, -84.000000)"
        fill={color}
        fillRule="nonzero"
      >
        <g id="Chevron-Down" transform="translate(868.000000, 84.000000)">
          <path
            d="M9.55,13.2893391 C9.35,13.2893391 9.25,13.1893391 9.15,13.0893391 L4.15,8.08933909 C3.95,7.88933909 3.95,7.48933909 4.15,7.18933909 C4.35,6.88933909 4.75,6.98933909 5.05,7.18933909 L9.65,11.7893391 L14.25,7.18933909 C14.45,6.98933909 14.85,6.98933909 15.15,7.18933909 C15.35,7.38933909 15.35,7.78933909 15.15,8.08933909 L10.15,13.0893391 C9.85,13.2893391 9.75,13.2893391 9.55,13.2893391 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowDown;
