/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import Analytics from 'helpers/Analytics';
import Modal from 'components/Modal';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import asyncActionStates from 'helpers/asyncActionStates';
import { MODAL_TYPES } from 'helpers/EditMode';
import { Request } from '@opusonesolutions/gridos-app-framework';
import { ImportsContext } from 'routes/ImportCIM/modules/importsContext';

import './DropdownSections.scss';
import { handleTooltipMessageForNetworkVersion } from 'helpers/utils';
import SelectType from './helpers';
import ActionMenu from './ActionMenu';

class BranchSection extends Component {
  state = {
    createBranchOpen: false,
    branchName: '',
    branchNotes: '',
    invalidName: false,
    selectedType: SelectType.NONE,
  };

  getCreateOption = () => {
    if (this.props.newBranchReq === asyncActionStates.LOADING) {
      return { id: 'add', contents: 'loading...' };
    }
    return {
      id: 'add',
      contents: 'Create Network Version',
      disabled: !this.props.permissions.has('create_workspace_branch'),
      tooltip: handleTooltipMessageForNetworkVersion(
        'add',
        this.props.permissions.has('create_workspace_branch'),
        this.props.inEditMode,
        this.props.permissions.has('delete_workspace_branch'),
        this.props.permissions.has('download_CIM_xml'),
        this.props.branch,
      ),
      type: 'standard',
    };
  };

  getDeleteOption = () =>
    this.props.view !== 'results' && {
      id: 'delete',
      contents: 'Delete',
      tooltip: handleTooltipMessageForNetworkVersion(
        'delete',
        this.props.permissions.has('create_workspace_branch'),
        this.props.inEditMode,
        this.props.permissions.has('delete_workspace_branch'),
        this.props.permissions.has('download_CIM_xml'),
        this.props.branch,
      ),
      disabled:
        !(
          this.props.branch !== 'master' && this.props.permissions.has('delete_workspace_branch')
        ) || this.props.inEditMode,
      type: 'standard',
    };

  getActionMenuOptions = () => [
    this.getCreateOption(),
    {
      id: 'download',
      contents: 'Download',
      tooltip: handleTooltipMessageForNetworkVersion(
        'download',
        this.props.permissions.has('create_workspace_branch'),
        this.props.inEditMode,
        this.props.permissions.has('delete_workspace_branch'),
        this.props.permissions.has('download_CIM_xml'),
        this.props.branch,
      ),
      disabled: !this.props.permissions.has('download_CIM_xml') || this.props.inEditMode,
      type: 'standard',
    },
    this.getDeleteOption(),
  ];

  getBranchOptions = branches => {
    const masterBranch = [
      { label: 'As Built', value: 'master', disabled: this.props.disableToEditMaster },
    ];

    if (branches.length > 1) {
      const sortedBranches = branches.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      return sortedBranches.reduce((list, branch) => {
        if (branch.name !== 'master') {
          return list.concat({ label: branch.name, value: branch.name });
        }
        return list;
      }, masterBranch);
    }

    return masterBranch;
  };

  hasPendingImports = () => {
    const {
      imports: { pending },
      initialized,
    } = this.context;
    return pending.length || !initialized;
  };

  handleCreateBranchClick = () => {
    if (this.props.inEditMode) {
      this.props.saveModalOpen(MODAL_TYPES.SAVEAS);
    } else {
      this.setState(prevState => ({ createBranchOpen: !prevState.createBranchOpen }));
    }
  };

  downloadBranch = async () => {
    const { branch, workspace } = this.props;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/xml`);
    Analytics.logEvent('Download Network Version', 'Network Version');
    try {
      const { data } = await request.get();
      const blob = new Blob([data], { type: 'application/xml' });

      const userBranch = branch === 'master' ? 'as_built' : branch;
      FileSaver.saveAs(blob, `${workspace}_${userBranch}.xml`);
    } catch (err) {
      this.props.displayAlertMessage(
        'Download Failed',
        'There was a problem downloading your selected file. Please try again.',
        null,
        () => this.downloadBranch(),
      );
    }
  };

  handleDeleteBranchClick = () => {
    this.props.deleteBranch(this.props.workspace, this.props.branch);
    Analytics.logEvent('Delete Network Version', 'Network Version');
  };

  /* Handle Events */
  handleNameChange = ({ target }) => {
    const name = target.value;
    const invalidName = name.length === 0 || name.match(/[^A-Za-z0-9-_]/g) !== null;
    const duplicateName = this.props.branches.map(br => br.name).includes(name);
    this.setState({
      branchName: name,
      invalidName: invalidName || duplicateName,
    });
  };

  handleNotesChange = ({ target }) => {
    if (target.value.length < 140) {
      this.setState({ branchNotes: target.value });
    }
  };

  handleBranchChange = ({ value }) => {
    if (this.props.inEditMode) {
      this.props.saveModalOpen(MODAL_TYPES.VERSION, value);
    } else if (value !== this.props.branch) {
      this.props.updateSelectedBranch(value, true);
    }
  };

  handleCloseModal = () => {
    this.setState({
      branchName: '',
      invalidName: false,
      createBranchOpen: false,
    });
  };

  handleBranchCreation = () => {
    this.props.createBranch(
      this.props.workspace,
      this.state.branchName,
      this.props.branch,
      this.state.branchNotes,
    );
    this.handleCloseModal();
  };

  handleKeyPress = e => {
    if (e.charCode === 13) {
      this.handleBranchCreation();
    }
  };

  handleActionMenuItemOnClick = id => {
    if (id === 'download') {
      this.downloadBranch();
    } else if (id === 'add') {
      this.handleCreateBranchClick();
    } else {
      this.handleDeleteBranchClick();
    }
  };

  setSelectedType = type => {
    this.setState({ selectedType: type });
  };

  render() {
    return (
      <div
        className="branch-scenario-section branch-section"
        id="branch-selector-container"
        data-test="branch-selector"
      >
        <div className="select-container">
          <div className="select-top-row">
            <p className="select-label">{this.props.title}</p>
          </div>
          <div className="select-control-wrapper">
            <Select
              className="workspace-selection-select"
              theme={this.props.theme}
              options={this.getBranchOptions(this.props.branches)}
              value={this.props.branch}
              onChange={this.handleBranchChange}
              clearable={false}
              searchable={false}
              id="branch-selector"
              type={this.props.selectType}
              onMenuOpen={() => {
                this.setState({ selectedType: SelectType.MENU });
              }}
              onMenuClose={() => {
                this.setState({ selectedType: SelectType.NONE });
              }}
            />
            {this.props.view === 'comparison' ? (
              ''
            ) : (
              <ActionMenu
                dropdownId="branch"
                dropdownOnClick={id => {
                  this.handleActionMenuItemOnClick(id);
                }}
                menuOptions={this.getActionMenuOptions()}
                selectedType={this.state.selectedType}
                setSelectedType={this.setSelectedType}
              />
            )}
          </div>
        </div>
        <Modal
          active={this.state.createBranchOpen}
          onCancel={this.handleCloseModal}
          onConfirm={this.handleBranchCreation}
          width="320px"
          title="Create New Network Version"
          theme={this.props.theme}
          disableConfirm={!this.state.branchName || this.state.invalidName}
        >
          <div className="create-modal">
            <label htmlFor="branch-name">Enter New Version Name:</label>
            <TextInput
              id="branch-name"
              value={this.state.branchName}
              onChange={this.handleNameChange}
              onKeyPress={this.handleKeyPress}
              required
              invalid={this.state.invalidName}
              validationMessage="Must be unique, use at least 1 character and can not include special
              characters or spaces."
              theme={this.props.theme}
            />
            <label htmlFor="branch-notes">Notes:</label>
            <TextInput
              id="branch-notes"
              value={this.state.branchNotes}
              onChange={this.handleNotesChange}
              onKeyPress={this.handleKeyPress}
              theme={this.props.theme}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

BranchSection.contextType = ImportsContext;

BranchSection.defaultProps = {
  branches: [],
  newBranchReq: 0,
  createBranch: null,
  title: 'Network Version',
  deleteBranch: null,
  saveModalOpen: null,
  displayAlertMessage: null,
  inEditMode: false,
  permissions: null,
  selectType: 'default',
  disableToEditMaster: false,
};

BranchSection.propTypes = {
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  createBranch: PropTypes.func,
  updateSelectedBranch: PropTypes.func.isRequired,
  deleteBranch: PropTypes.func,
  theme: PropTypes.string.isRequired,
  newBranchReq: PropTypes.number,
  branches: PropTypes.array,
  permissions: PropTypes.object,
  displayAlertMessage: PropTypes.func,
  view: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool,
  saveModalOpen: PropTypes.func,
  title: PropTypes.string,
  selectType: PropTypes.string,
  disableToEditMaster: PropTypes.bool,
};

export default BranchSection;
