import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import Tour from 'reactour';
import classNames from 'classnames';
import { apm } from '@opusonesolutions/gridos-app-framework';
import Button from 'components/Button';
import IntlContextProvider from 'contexts/IntlContext';
import WorkspaceSettingsContextProvider from 'contexts/WorkspaceSettingsContext';
import Analytics from 'helpers/Analytics';
import ThemeContext from 'helpers/ThemeContext';
import browserHistory from 'routes/history';
import SettingsMenu from 'routes/SettingsMenu';
import { viewOptions } from 'helpers/Constants';
import AlertMessage from './AlertMessage';
import ErrorPage from './ErrorPage';
import { arrowPosition, createSteps } from './TourHelper';
import './CoreLayout.scss';
import '../../styles/core.scss';

class CoreLayout extends Component {
  state = {
    isTourOpen: !(localStorage.getItem('tour') === 'hide'),
    currentStep: 1,
  };

  componentDidMount() {
    this.setState({ steps: createSteps(this.closeTour) });
    const { isAuthenticated, productDetails, user } = this.props;
    if (isAuthenticated && productDetails && productDetails.GA && user) {
      // Page is loaded and we have a user. Time to initialize the GA tracking
      if (!Analytics.isInitialized()) {
        Analytics.initialize(productDetails.GA, user);
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdateChecks = [
      this.props.location.pathname !== nextProps.location.pathname,
      this.state.isTourOpen !== nextState.isTourOpen,
      this.state.error !== nextState.error,
      this.state.currentStep !== nextState.currentStep,
      this.props.permissions !== nextProps.permissions,
      this.props.alertMessage !== nextProps.alertMessage,
      this.props.theme !== nextProps.theme,
      this.props.view !== nextProps.view,
      this.props.inEditMode !== nextProps.inEditMode,
    ];
    if (shouldUpdateChecks.some(check => check)) {
      return true;
    }
    return false;
  }

  componentDidCatch(err, info) {
    this.setState({
      error: true,
      errorInfo: {
        info: info.componentStack,
        error: err.toString(),
      },
    });
    apm.captureError(err);
  }

  setTourState = () => {
    this.setState({
      isTourOpen: !(localStorage.getItem('tour') === 'hide'),
    });
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
    localStorage.setItem('tour', 'hide');
  };

  getRouteMatch = () => {
    const importMatch = matchPath(this.props.location.pathname, {
      path: '/import-cim/:workspace?/:branch?',
    });
    const settingsMatch = matchPath(this.props.location.pathname, {
      path: '/settings/:workspace',
    });
    const normalMatch = matchPath(this.props.location.pathname, {
      path: '/:workspace/:branch/',
    });
    return importMatch || settingsMatch || normalMatch;
  };

  handleProfileClick = () => {
    this.resetErrorState();
    browserHistory.push('/profile');
  };

  handleHomeClick = () => {
    this.resetErrorState();
    // if you are within a workspace, go to workspace root
    // otherwise go to workspace selection
    const routeMatch = this.getRouteMatch();
    const workspaceName = routeMatch ? `/${routeMatch.params.workspace}` : '';

    if (workspaceName) {
      browserHistory.push(`${workspaceName}/master/gis`);
    } else {
      browserHistory.push('/');
    }
  };

  resetErrorState = () => this.setState({ error: false, errorInfo: undefined });

  renderContents = () => {
    if (this.state.error) {
      return <ErrorPage onButtonClick={this.handleHomeClick} errorInfo={this.state.errorInfo} />;
    }
    return this.props.children;
  };

  isLoginPage = () => this.props.location.pathname.includes('login');

  setStep = curr => {
    if (curr + 1 !== this.state.currentStep) this.setState({ currentStep: curr + 1 });
  };

  render() {
    const {
      isAuthEnabled,
      permissions,
      inEditMode,
      actions,
      location,
      view,
      alertMessage,
      user,
      theme,
    } = this.props;
    const match = this.getRouteMatch();
    const workspace = match ? match.params.workspace : null;
    const branch = match ? match.params.branch : 'master';
    const onWorkspace = location.pathname.includes(`/${workspace}/${branch}/gis`);
    const getPageTitle = () => {
      let pageTitle = '';
      if (
        match &&
        ['/:workspace/:branch/', '/:workspace/workspace_overview'].includes(match.path) &&
        this.props.view
      ) {
        pageTitle = ` | ${
          this.props.view === 'gis' && this.props.inEditMode
            ? 'GIS Editor'
            : viewOptions[this.props.view]?.title
        }`;
      }
      return pageTitle?.toUpperCase();
    };
    return (
      <ThemeContext.Provider value={theme}>
        <IntlContextProvider workspace={workspace}>
          <WorkspaceSettingsContextProvider
            workspace={workspace}
            permissions={permissions}
            isAuthEnabled={isAuthEnabled}
          >
            <div className={`container text-center ${theme} ${inEditMode && 'edit_mode'}`}>
              <div className="top-nav">
                <div className="top-nav__left-wapper">
                  <button className="top-nav__btn" onClick={this.handleHomeClick} type="button">
                    <img
                      className="top-nav__img"
                      alt="GridOS Logo"
                      src={inEditMode ? '/ge-logo-white.png' : '/ge-logo.png'}
                    />
                  </button>
                  <h3 className="top-nav__title top-nav--left">
                    Opus One DERMS{' '}
                    <span className="circle_dot_icon">
                      <i className="material-icons" aria-hidden="true">
                        circle
                      </i>
                    </span>{' '}
                    ANALYTICS
                    {getPageTitle()}
                  </h3>
                </div>
                {!this.isLoginPage() && (
                  <div className="top-nav--right">
                    {isAuthEnabled && user && user.email && (
                      <button className="username" onClick={this.handleProfileClick} type="button">
                        <i className="material-icons">account_circle</i>
                        <p className="username-name">{user.email}</p>
                      </button>
                    )}
                    <SettingsMenu
                      actions={actions}
                      theme={theme}
                      showTour={() => this.setState({ isTourOpen: true })}
                      view={view}
                      inEditMode={inEditMode}
                      workspace={workspace}
                    />
                  </div>
                )}
              </div>
              <div
                className={classNames({
                  'core-layout__viewport': true,
                  'core-layout__viewport--dark': theme === 'dark',
                })}
              >
                {alertMessage && (
                  <AlertMessage
                    alertMessage={alertMessage}
                    clearAlert={actions.clearAlertMessage}
                  />
                )}
                {this.renderContents()}
                {onWorkspace && view === 'gis' && !inEditMode && (
                  <Tour
                    steps={this.state.steps}
                    startAt={0}
                    isOpen={this.state.isTourOpen}
                    onRequestClose={this.closeTour}
                    showNumber={false}
                    accentColor="#FFFFFF"
                    showCloseButton={false}
                    className={classNames({
                      top: arrowPosition.top.includes(this.state.currentStep),
                      left: arrowPosition.left.includes(this.state.currentStep),
                    })}
                    nextButton={<Button type="tour">Next</Button>}
                    prevButton={<Button type="tour">Prev</Button>}
                    getCurrentStep={this.setStep}
                    lastStepNextButton={
                      <Button type="tour" onClick={this.closeTour}>
                        <b>Got it</b>
                      </Button>
                    }
                    showNavigationNumber={false}
                    disableInteraction
                    closeWithMask={false}
                  />
                )}
              </div>
            </div>
          </WorkspaceSettingsContextProvider>
        </IntlContextProvider>
      </ThemeContext.Provider>
    );
  }
}

CoreLayout.defaultProps = {
  alertMessage: null,
  productDetails: null,
  user: null,
};

CoreLayout.propTypes = {
  actions: PropTypes.shape({
    clearAlertMessage: PropTypes.func,
  }).isRequired,
  alertMessage: PropTypes.object,
  children: PropTypes.element.isRequired,
  location: PropTypes.object.isRequired,
  theme: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  inEditMode: PropTypes.bool.isRequired,

  // From the Auth Context
  isAuthEnabled: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  permissions: PropTypes.object.isRequired,
  productDetails: PropTypes.shape({
    GA: PropTypes.string,
  }),
  user: PropTypes.shape({
    email: PropTypes.string,
    userID: PropTypes.string,
  }),
};

export default CoreLayout;
