import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { chunkArray, handleTooltipMessageForScenario } from 'helpers/utils';
import { Request } from '@opusonesolutions/gridos-app-framework';
import nullable from 'helpers/nullablePropType';
import asyncActionStates from 'helpers/asyncActionStates';
import Analytics from 'helpers/Analytics';
import { ActivityLogContext } from 'contexts/ActivityLogContext';
import { ScenarioTypes } from 'helpers/scenarios';
import ScenarioModal from './ScenarioModal';
import './DropdownSections.scss';
import { ANALYSIS_TYPES } from '../routes/Network/helpers/NetworkHelpers';
import ScenarioSelect from './ScenarioSelect';
import SelectType from './helpers';
import ActionMenu from './ActionMenu';

const rateLimit = Object.freeze({
  /** the maximum number of concurrent requests to make */
  concurrentRequests: 5,
  /** the time to wait before subsequent requests (ms) */
  resetTimeout: 1000,
});

class ScenarioSection extends Component {
  initilUploadStates = {
    scenarioReq: asyncActionStates.INITIAL,
    scenarioErr: null,
    scenarioFileUploads: {},
    fileUploadErrors: {},
    bulkAssetScheduleUpload: asyncActionStates.INITIAL,
    bulkAssetScheduleError: null,
  };

  state = {
    scenarioModalOpen: false,
    scenarioModalMode: 'create',
    ...this.initilUploadStates,
    selectedType: SelectType.NONE,
  };

  componentDidMount() {
    const { actions, workspace, branch } = this.props;
    actions.fetchScenarios(workspace, branch);
  }

  componentDidUpdate(prevProps, prevState) {
    const { actions, workspace, branch, scenario, scenarios } = this.props;
    if (prevProps.workspace !== workspace || prevProps.branch !== branch) {
      actions.fetchScenarios(workspace, branch);
    }
    if (
      scenario === '' &&
      scenarios.length === 1 &&
      prevProps.scenarios.length !== scenarios.length
    ) {
      this.handleScenarioChange(scenarios[0].value, scenarios[0].type);
    }

    if (
      this.state.scenarioReq === asyncActionStates.SUCCESS &&
      this.state.scenarioReq !== prevState.scenarioReq
    ) {
      this.setState({
        scenarioModalMode: 'edit',
      });
    }
  }

  handleScenarioChange = (scenarioId, scenarioType) => {
    if (!scenarioId) {
      // Clearing the selection
      this.props.actions.clearSelectedScenario(true);
    } else if (scenarioId !== this.props.scenario) {
      this.props.actions.updateSelectedScenario(
        this.props.workspace,
        this.props.branch,
        scenarioId,
        scenarioType,
      );
    }
  };

  handleClose = (hasNewScenario = false) => {
    this.setState({ scenarioModalOpen: false });
    if (hasNewScenario) {
      const { workspace, branch, scenario } = this.props;
      this.props.actions.updateAndSelectScenario(workspace, branch, scenario);
    }
    this.setState({ ...this.initilUploadStates });
  };

  uploadSingleScenarioFile = async (workspace, branch, scenario, feeder, file) => {
    const uploadURL = `/api/workspace/${workspace}/branch/${branch}/asset_schedule/${feeder}`;
    this.setState(prevState => ({
      scenarioFileUploads: {
        ...prevState.scenarioFileUploads,
        [feeder]: asyncActionStates.LOADING,
      },
    }));
    try {
      await new Request(uploadURL).post(file, {
        params: {
          scenario_id: scenario,
          asset_type: 'feeder',
          schedule_type: 'Feeder',
        },
      });
      this.setState(prevState => ({
        scenarioFileUploads: {
          ...prevState.scenarioFileUploads,
          [feeder]: asyncActionStates.SUCCESS,
        },
      }));
    } catch (err) {
      let message = 'An Error Occured Uploading Selected File';
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      this.setState(prevState => ({
        scenarioFileUploads: {
          ...prevState.scenarioFileUploads,
          [feeder]: asyncActionStates.ERROR,
        },
        fileUploadErrors: { ...prevState.fileUploadErrors, [feeder]: message },
      }));
    }
  };

  uploadScenarioFiles = async (workspace, branch, scenario, files) => {
    const chunks = chunkArray(Object.entries(files), rateLimit.concurrentRequests);
    const uploadFile = ([feeder, formData]) =>
      this.uploadSingleScenarioFile(workspace, branch, scenario, feeder, formData);
    for (let batch = 0; batch < chunks.length; batch += 1) {
      const requests = chunks[batch].map(uploadFile);
      /* eslint-disable no-await-in-loop */
      // ignore lint because we specifically dont want them all running at once
      await Promise.all(requests);
      // wait 1s between batches
      await new Promise(resolve => setTimeout(resolve, rateLimit.resetTimeout));
      /* eslint-enable no-await-in-loop */
    }
  };

  uploadBulkAssetSchedule = async (workspace, branch, scenario, file) => {
    const uploadUrl = `/api/workspace/${workspace}/branch/${branch}/asset_schedule_bulk`;
    this.setState({ bulkAssetScheduleUpload: asyncActionStates.LOADING });
    try {
      await new Request(uploadUrl).post(file, {
        params: { scenario_id: scenario },
      });
      this.setState({
        bulkAssetScheduleUpload: asyncActionStates.SUCCESS,
        bulkAssetScheduleError: '',
      });
    } catch (err) {
      let message = 'An Error Occured Uploading Selected File';
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      this.setState({
        bulkAssetScheduleUpload: asyncActionStates.ERROR,
        bulkAssetScheduleError: message,
      });
    }
  };

  createSnapshotScenario = async (workspace, branch, name, timestamp, feeders) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
    try {
      const response = await new Request(url).post({
        scenario_name: name,
        scenario_type: ScenarioTypes.snapshot,
        timepoint: timestamp,
        container: feeders.map(fdr => fdr.id),
      });
      Analytics.logEvent('Uploaded Scenario', 'Scenarios');
      const { scenario_id } = response.data;
      this.props.actions.updateSelectedScenario(
        workspace,
        branch,
        scenario_id,
        ScenarioTypes.snapshot,
      );
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while creating scenario';
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  deleteScenario = async (workspace, branch, scenarioId) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenarioId}`;
    try {
      await new Request(url).delete();
      await this.props.actions.fetchScenarios(workspace, branch);
      await this.props.actions.clearSelectedScenario();
      this.setState({ ...this.initilUploadStates });
    } catch (err) {
      let message = 'An error occured while deleting scenario';
      if (err?.response?.data?.message) {
        message = err?.response?.data?.message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  updateScenario = async (workspace, branch, scenario, name, files = {}, asset_schedule = null) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios/${scenario}`;
    try {
      await new Request(url).patch({
        scenario_name: name,
      });
      Analytics.logEvent('Updated Scenario', 'Scenarios');
      if (Object.keys(files).length > 0) {
        this.uploadScenarioFiles(workspace, branch, scenario, files);
      }
      if (asset_schedule) {
        this.uploadBulkAssetSchedule(workspace, branch, scenario, asset_schedule);
      }
      this.props.actions.fetchScenarios(workspace, branch);
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while updating scenario';
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  createScenario = async (workspace, branch, name, files = {}, asset_schedule = null) => {
    this.setState({ scenarioReq: asyncActionStates.LOADING, scenarioErr: null });
    const url = `/api/workspace/${workspace}/branch/${branch}/qsts_scenarios`;
    try {
      const results = await new Request(url).post({
        scenario_name: name,
        scenario_type: ScenarioTypes.timeseries,
      });
      Analytics.logEvent('Uploaded Scenario', 'Scenarios');
      const { scenario_id } = results.data;
      if (Object.keys(files).length > 0) {
        this.uploadScenarioFiles(workspace, branch, scenario_id, files);
      }
      if (asset_schedule) {
        this.uploadBulkAssetSchedule(workspace, branch, scenario_id, asset_schedule);
      }
      this.props.actions.updateSelectedScenario(
        workspace,
        branch,
        scenario_id,
        ScenarioTypes.timeseries,
      );
      this.setState({ scenarioReq: asyncActionStates.SUCCESS });
    } catch (err) {
      let message = 'An error occured while creating scenario';
      if (err?.response?.data?.message) {
        message = err.response.data.message;
      }
      this.setState({ scenarioReq: asyncActionStates.ERROR, scenarioErr: message });
    }
  };

  handleScenarioCreation = (name, files, assetSchedulFile) => {
    if (this.props.canCreate) {
      this.createScenario(this.props.workspace, this.props.branch, name, files, assetSchedulFile);
    }
  };

  handleSnapshotScenarioCreation = (name, timepoint) => {
    if (this.props.canCreate) {
      this.createSnapshotScenario(
        this.props.workspace,
        this.props.branch,
        name,
        timepoint,
        this.props.allFeeders,
      );
    }
  };

  handleScenarioEdit = (scenarioId, name, files, assetSchedulFile) => {
    if (this.props.canEdit) {
      this.updateScenario(
        this.props.workspace,
        this.props.branch,
        scenarioId,
        name,
        files,
        assetSchedulFile,
      );
    }
  };

  handleReUpload = (scenarioFiles, assetSchedulFile) => {
    if (scenarioFiles) {
      this.uploadScenarioFiles(
        this.props.workspace,
        this.props.branch,
        this.props.scenario,
        scenarioFiles,
      );
    }
    if (assetSchedulFile) {
      this.uploadBulkAssetSchedule(
        this.props.workspace,
        this.props.branch,
        this.props.scenario,
        assetSchedulFile,
      );
    }
  };

  handleMenuSelection = type => {
    switch (type) {
      case 'delete':
        if (this.props.canDelete) {
          return this.deleteScenario(this.props.workspace, this.props.branch, this.props.scenario);
        }
        return null;
      case 'edit':
        this.setState({
          scenarioModalOpen: true,
          scenarioModalMode: 'edit',
        });
        return null;
      default:
        return null;
    }
  };

  handleCreateOnClick = () =>
    this.setState({ scenarioModalOpen: true, scenarioModalMode: 'create' });

  handleEditOnClick = () =>
    this.setState({
      scenarioModalOpen: true,
      scenarioModalMode: 'edit',
    });

  handleDeleteOnClick = () =>
    this.deleteScenario(this.props.workspace, this.props.branch, this.props.scenario);

  handleOnClickOnActionItems = id => {
    if (id === 'add') {
      this.handleCreateOnClick();
    } else if (id === 'edit') {
      this.handleEditOnClick();
    } else {
      this.handleDeleteOnClick();
    }
  };

  getActionMenuOptions = () => [
    {
      id: 'add',
      contents: 'Create Scenario',
      tooltip: handleTooltipMessageForScenario('add', this.props.canCreate, this.props.scenario),
      disabled: !this.props.canCreate,
      type: 'standard',
    },
    {
      id: 'edit',
      contents: 'Edit scenario',
      tooltip: handleTooltipMessageForScenario('edit', this.props.canCreate, this.props.scenario),
      disabled: !this.props.canEdit || this.props.scenarios.length === 0 || !this.props.scenario,
      type: 'standard',
    },
    {
      id: 'delete',
      contents: 'Delete scenario',
      tooltip: handleTooltipMessageForScenario('delete', this.props.canCreate, this.props.scenario),
      disabled: !this.props.canDelete || this.props.scenarios.length === 0 || !this.props.scenario,
      type: 'standard',
    },
  ];

  setSelectedType = type => {
    this.setState({ selectedType: type });
  };

  render() {
    const { scenarios, scenario, scenariosReq, disableToSelectScenario } = this.props;
    const { logEntries } = this.context;
    const cloningEntry =
      logEntries.find(entry => entry.activity_type === ANALYSIS_TYPES.CLONING_SCENARIOS) || {};
    const cloningScenarioReq =
      Object.keys(cloningEntry) && cloningEntry.status ? cloningEntry.status : '';
    const branchCopying =
      this.props.newBranchReq === asyncActionStates.LOADING || cloningScenarioReq === 'RUNNING';
    const getPlaceholderMessage = () => {
      if (branchCopying) {
        return 'Copying...';
      }
      if (scenariosReq === asyncActionStates.LOADING) {
        return 'Loading...';
      }
      return 'Select...';
    };

    return (
      <div
        className="branch-scenario-section scenario-section"
        id="scenario-selector-container"
        data-test="scenario-selector"
      >
        <div className="select-container">
          <div className="select-top-row">
            <p className="select-label">Scenario</p>
          </div>
          <div className="select-control-wrapper">
            <ScenarioSelect
              theme={this.props.theme}
              scenarios={scenarios}
              scenario={scenario}
              loading={branchCopying || scenariosReq === asyncActionStates.LOADING}
              loadingMessage={getPlaceholderMessage()}
              onScenarioChange={this.handleScenarioChange}
              disableToSelectScenario={disableToSelectScenario}
              handleMenuOpen={() => {
                this.setState({ selectedType: SelectType.MENU });
              }}
              handleMenuClose={() => {
                this.setState({ selectedType: SelectType.NONE });
              }}
              id="scenario-selector"
            />
            <ActionMenu
              dropdownId="scenario"
              dropdownOnClick={id => {
                this.handleOnClickOnActionItems(id);
              }}
              menuOptions={this.getActionMenuOptions()}
              selectedType={this.state.selectedType}
              setSelectedType={this.setSelectedType}
            />
          </div>
        </div>

        {this.state.scenarioModalOpen && (
          <ScenarioModal
            scenario={this.state.scenarioModalMode === 'edit' ? this.props.scenario : null}
            scenarioReq={this.state.scenarioReq}
            scenarioErr={this.state.scenarioErr}
            selectedFeeders={this.props.selectedFeeders}
            theme={this.props.theme}
            handleClose={this.handleClose}
            handleScenarioCreation={this.handleScenarioCreation}
            handleSnapshotScenarioCreation={this.handleSnapshotScenarioCreation}
            handleScenarioEdit={this.handleScenarioEdit}
            handleReUpload={this.handleReUpload}
            scenarios={this.props.scenarios}
            scenarioFileUploads={this.state.scenarioFileUploads}
            fileUploadErrors={this.state.fileUploadErrors}
            bulkAssetScheduleUpload={this.state.bulkAssetScheduleUpload}
            bulkAssetScheduleUploadError={this.state.bulkAssetScheduleError}
          />
        )}
      </div>
    );
  }
}

ScenarioSection.contextType = ActivityLogContext;

ScenarioSection.defaultProps = {
  scenarios: [],
  canDelete: false,
  canCreate: false,
  canEdit: false,
  newBranchReq: 0,
  selectedFeeders: [],
  disableToSelectScenario: false,
};

ScenarioSection.propTypes = {
  branch: PropTypes.string.isRequired,
  workspace: PropTypes.string.isRequired,
  scenario: nullable(PropTypes.string).isRequired,
  theme: PropTypes.string.isRequired,
  scenarios: PropTypes.array,
  canDelete: PropTypes.bool,
  canCreate: PropTypes.bool,
  canEdit: PropTypes.bool,
  newBranchReq: PropTypes.number,
  selectedFeeders: PropTypes.array,
  allFeeders: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    clearSelectedScenario: PropTypes.func,
    updateSelectedScenario: PropTypes.func,
    updateAndSelectScenario: PropTypes.func,
    fetchScenarios: PropTypes.func,
  }).isRequired,
  scenariosReq: PropTypes.number.isRequired,
  disableToSelectScenario: PropTypes.bool,
};

export default ScenarioSection;
