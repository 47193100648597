import React, { FunctionComponent, useState, useMemo } from 'react';
import { SimulationOptions, selectedDERType } from 'types/analysis';
import { optionType } from 'types/edit';
import ActionButtons from 'components/ActionButtons';
import { useRequestEffect } from '@opusonesolutions/gridos-app-framework';
import { alphabetizeByKey } from 'helpers/utils';
import NodeSelector from './NodeSelector';
import { ANALYSIS_TYPES } from '../../helpers/NetworkHelpers';
import DERAssetSelector from './DERAssetSelector';

type SpecificAnalysisConfigProps = {
  selectedAnalysisType: string;
  simulationOptions: SimulationOptions;
  setSimulationOptions: React.Dispatch<React.SetStateAction<SimulationOptions>>;
  onPreviousClick: () => void;
  onClickNextBtn: (argsConfig: { [key: string]: any }) => void;
  toggleModal: (modalOpen: boolean) => void;
  workspace: string;
  branch: string;
  selectedContainers?: { id: string }[];
  selectedAsset?: { id: string; name: string; class: string } | null;
};

const SpecificAnalysisConfig: FunctionComponent<SpecificAnalysisConfigProps> = ({
  selectedAnalysisType,
  simulationOptions,
  setSimulationOptions,
  onPreviousClick,
  onClickNextBtn,
  toggleModal,
  workspace,
  branch,
  selectedContainers = [],
  selectedAsset = null,
}) => {
  const derAssetClasses = ['Battery', 'InverterPV', 'SynchronousMachine', 'RunOfRiverHydro', 'CHP'];
  const { HOSTING_CAPACITY, EV_CAPACITY, BATTERY_SIZING, OPERATIONAL_ENVELOPE } = ANALYSIS_TYPES;
  const isDERAsset = selectedAsset && derAssetClasses.includes(selectedAsset.class);
  const [selectedDERs, setSelectedDERs] = useState<selectedDERType>(
    isDERAsset
      ? {
          [selectedAsset?.id ?? '']: {
            isSelected: true,
            class: selectedAsset?.class ?? '',
            name: selectedAsset?.name ?? '',
            id: selectedAsset?.id ?? '',
          },
        }
      : {},
  );
  const [singleOrAll, setSingleOrAll] = useState('single');
  const isNode = selectedAsset && selectedAsset?.class === 'ConnectivityNode';
  const [selectedNodes, setSelectedNodes] = useState<optionType[]>(
    isNode ? [{ value: selectedAsset?.id ?? '', label: selectedAsset?.name ?? '' }] : [],
  );
  const [phase, setPhase] = useState('');
  const isNodalAnalysis = [HOSTING_CAPACITY, EV_CAPACITY, BATTERY_SIZING].includes(
    selectedAnalysisType,
  );
  const isOperationalSel = selectedAnalysisType === OPERATIONAL_ENVELOPE;
  const { data: nodeObjects } = useRequestEffect<{ [key: string]: { [key: string]: any } }>({
    url: `/api/workspace/${workspace}/branch/${branch}/class/ConnectivityNode`,
    method: 'get',
    refetchOnChange: [workspace, branch],
    initialData: {},
    blockRequest: () =>
      !(workspace && branch && selectedContainers && selectedContainers?.length && isNodalAnalysis),
    toast: {
      error: 'An error occurred loading the node list',
    },
  });

  const nodeOptions: optionType[] = useMemo(() => {
    const containerList = selectedContainers?.map(c => c.id);
    const nodeList: { [key: string]: { [key: string]: string } } = {};
    try {
      if (containerList.length > 0) {
        Object.keys(nodeObjects ?? {}).forEach(nodeID => {
          if (
            containerList.includes(
              nodeObjects?.[nodeID].references['ConnectivityNode.ConnectivityNodeContainer'],
            )
          ) {
            nodeList[nodeID] = {
              id: nodeID,
              name: nodeObjects?.[nodeID].attributes['IdentifiedObject.name'],
              class: 'ConnectivityNode',
            };
          }
        });
      }
    } catch (err) {}
    return alphabetizeByKey(
      Object.entries(nodeList).map(([id, node]) => ({ value: id, label: node.name })),
      'label',
    );
  }, [selectedContainers, nodeObjects]);

  const isSingleNodeSel = singleOrAll === 'single';
  const invalidSingleNode =
    (isNodalAnalysis &&
      isSingleNodeSel &&
      (!selectedNodes ||
        !selectedNodes.length ||
        ([HOSTING_CAPACITY, EV_CAPACITY].includes(selectedAnalysisType) && !phase))) ||
    (selectedAnalysisType === BATTERY_SIZING && isSingleNodeSel && selectedNodes.length === 0);
  const invalidOPESel =
    isOperationalSel && !Object.values(selectedDERs).filter(obj => obj.isSelected).length;

  const argsConfig = () => {
    let argsObj = {};
    switch (selectedAnalysisType) {
      case HOSTING_CAPACITY:
      case EV_CAPACITY:
        argsObj = {
          nodes: isSingleNodeSel && selectedNodes[0]?.value ? [selectedNodes[0].value] : [],
          phases: phase,
        };
        break;
      case BATTERY_SIZING:
        argsObj = {
          nodes: (isSingleNodeSel ? selectedNodes : nodeOptions).map(node => ({
            id: node.value,
            name: node.label,
          })),
        };
        break;
      case OPERATIONAL_ENVELOPE:
        argsObj = {
          selectedDERs: Object.values(selectedDERs)
            .filter(der => der.isSelected)
            .map(der => der.id),
        };
        break;
      default:
        argsObj = {};
    }
    return argsObj;
  };
  return (
    <div className="specific-analysis-config">
      {isNodalAnalysis && (
        <NodeSelector
          selectedAnalysisType={selectedAnalysisType}
          simulationOptions={simulationOptions}
          setSimulationOptions={setSimulationOptions}
          nodeOptions={nodeOptions}
          singleOrAll={singleOrAll}
          setSingleOrAll={setSingleOrAll}
          phase={phase}
          setPhase={setPhase}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
        />
      )}
      {isOperationalSel && (
        <DERAssetSelector
          workspace={workspace}
          branch={branch}
          selectedContainers={selectedContainers}
          setSelectedDERs={setSelectedDERs}
          selectedDERs={selectedDERs}
        />
      )}
      <ActionButtons
        className="analysis-action-btns"
        onClickBackBtn={onPreviousClick}
        disableNext={invalidSingleNode || invalidOPESel}
        onClickNextBtn={() => onClickNextBtn(argsConfig())}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default SpecificAnalysisConfig;
