import { Request } from '@opusonesolutions/gridos-app-framework';
import Analytics from 'helpers/Analytics';
import { CLEAR_NETWORK_DATA, updateSelectedBranch } from './network';

const WORKSPACE_VALIDATION_START = 'WORKSPACE_VALIDATION_START';
const WORKSPACE_VALIDATION_FAILURE = 'WORKSPACE_VALIDATION_FAILURE';
const WORKSPACE_NOT_READY = 'WORKSPACE_NOT_READY';
const WORKSPACE_READY = 'WORKSPACE_READY';
const WORKSPACE_FIX_START = 'WORKSPACE_FIX_START';
const WORKSPACE_FIX_SUCCESS = 'WORKSPACE_FIX_SUCCESS';
const WORKSPACE_FIX_FAILURE = 'WORKSPACE_FIX_FAILURE';

function validateWorkspace(workspace) {
  return (dispatch, getState) => {
    const { branch } = getState().network;
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/validate`);

    dispatch({ type: WORKSPACE_VALIDATION_START });
    Analytics.logEvent('Ran Workspace Validation', 'Validate and Fix');
    return request
      .post()
      .then(({ data }) => {
        if (Object.keys(data).length > 0) {
          dispatch({
            type: WORKSPACE_NOT_READY,
            payload: data,
          });
        } else {
          dispatch({
            type: WORKSPACE_READY,
          });
        }
      })
      .catch(errors => {
        dispatch({
          type: WORKSPACE_VALIDATION_FAILURE,
          payload: errors,
        });
      });
  };
}

function applyFixers(workspace, initialErrors) {
  return (dispatch, getState) => {
    const {
      network: { branch, displayBranch },
      feeders: { selected },
    } = getState();
    const request = new Request(`/api/workspace/${workspace}/branch/${branch}/fix`);
    Analytics.logEvent('Applied Fixers', 'Validate and Fix');

    dispatch({
      type: WORKSPACE_FIX_START,
    });
    return request
      .post(initialErrors, { timeout: 6000000 })
      .then(({ data }) => {
        dispatch(updateSelectedBranch(displayBranch, true, selected));
        return data;
      })
      .then(({ errors }) => {
        dispatch({
          type: WORKSPACE_FIX_SUCCESS,
          errors,
        });
      })
      .catch(requestErrors => {
        dispatch({
          type: WORKSPACE_FIX_FAILURE,
          payload: requestErrors,
        });
      });
  };
}

export const validationActions = {
  validateWorkspace,
  applyFixers,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  errors: {},
  workspaceErrors: null,
  fixersRunning: false,
  validationLoading: false,
  pendingReq: {},
};

export default function WorkspaceReducer(state = initialState, action) {
  switch (action.type) {
    case WORKSPACE_FIX_START:
      return {
        ...state,
        fixersRunning: true,
        // Clear any old errors
        workspaceErrors: null,
      };
    case WORKSPACE_FIX_SUCCESS:
      return {
        ...state,
        fixersRunning: false,
        workspaceErrors: action.errors,
      };
    case WORKSPACE_FIX_FAILURE:
      return {
        ...state,
        errors: {
          ...state.errors,
          workspaceFix: action.payload,
        },
        fixersRunning: false,
      };
    case WORKSPACE_VALIDATION_START:
      return {
        ...state,
        validationLoading: true,
        // Clear any old errors
        workspaceErrors: null,
      };
    case WORKSPACE_NOT_READY:
      return {
        ...state,
        workspaceErrors: action.payload,
        validationLoading: false,
      };
    case WORKSPACE_READY:
      return { ...state, validationLoading: false };
    case WORKSPACE_VALIDATION_FAILURE:
      return {
        ...state,
        errors: { ...state.errors, validation: action.payload },
        validationLoading: false,
      };
    case CLEAR_NETWORK_DATA:
      return initialState;
    default:
      return state;
  }
}
